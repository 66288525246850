import React, { ReactElement, useEffect, useState } from 'react';
import Config from '../../../app-config.json';
import { CheckCircleIcon, XCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import useDarkModeStore from '../../../stores/useDarkModeStore';
import { useErrorBoundary } from 'react-error-boundary';
import Title from '../../Other/ViewHeader';
import Button from '../../Controls/PrimaryButton/PrimaryButton';
import axios from 'axios';

interface BaseListViewProps {
  apiURL: string;
  title: string;
  desc: string;
  columns: string[];
  rowData: string[];
  isEdited?: boolean;
  onRowSelect: (id: any) => void;
  onAddButtonClick: (trigger: boolean) => void;
  searchBar?: ReactElement;
}

interface Data {
  [key: string]: any;
}

function createData<T extends Data>(data: T): T {
  return data;
}

export default function BaseListView<T extends Data>({
  apiURL,
  title,
  desc,
  columns,
  rowData,
  searchBar,
  isEdited = false,
  onRowSelect,
  onAddButtonClick,
}: BaseListViewProps) {
  const { darkMode } = useDarkModeStore() as { darkMode: boolean };
  const [rows, setRows] = useState<Data[]>([]);
  const [page, setPage] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [firstTimePageLoad, setFirstTimePageLoad] = React.useState(true);
  const [showError, setShowError] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  // UserErrorBoundary Hook allows us to catch async errors
  const { showBoundary } = useErrorBoundary();

  // State variable to store the search results
  // searchResults is an array of Data objects
  // setSearchResults is a function to update the searchResults state
  const [searchResults, setSearchResults] = useState<Data[]>([]);

  // Function to handle the search results received from the search bar component
  // It takes an array of Data objects as a parameter
  // The function updates the searchResults state with the received results
  // It also updates the totalRecordCount state with the length of the received results
  // Additionally, it resets the page to 0 when new search results are received
  const handleSearchResults = (results: Data[]) => {
    if (results.length === 0) {
      // If search results are empty, reset searchResults and update totalRecordCount with the total number of source feeds
      setSearchResults([]);
      setTotalRecordCount(rows.length);
      setFirstTimePageLoad(true);
    } else {
      // If search results are not empty, update searchResults and totalRecordCount with the search results count
      setSearchResults(results);
      setTotalRecordCount(results.length);
    }
    setPage(0); // Reset page to 0
  };

  function populateListView(orgId: number = 1, incDisabled: boolean = false) {
    const offset = page * pageSize;
    const limit = pageSize;

    if (searchResults.length >= 1) {
      // If there are search results in the rows state, slice them based on the offset and limit
      const paginatedResults = searchResults.slice(offset, offset + limit);
      setRows(paginatedResults);
    } else {
      // If there are no search results, fetch data from the API
      axios
        .get(`${Config.apiRootURL}${apiURL}&offset=${offset}&limit=${limit}`)
        .then((response) => {
          const json = response.data;
          const rowData: Data[] = json.results.map((item: any) => createData(item));
          setRows(rowData);

          if (firstTimePageLoad) {
            setTotalRecordCount(json.totalRecordCount);
            setFirstTimePageLoad(false);
          }
        })
        .catch((error: any) => {
          showBoundary(error);
        });
    }
  }

  const handleRowClick = (row: Data) => {
    onRowSelect(row.id);
  };

  function handlePreviousButtonClick() {
    let totalPages = Math.ceil(totalRecordCount / pageSize);
    let newPageNumber = page - 1;
    if (newPageNumber < totalPages) setPage(newPageNumber);
    else return;
  }

  function handleNextButtonClick() {
    let totalPages = Math.ceil(totalRecordCount / pageSize);
    let newPageNumber = page + 1;
    if (newPageNumber < totalPages) setPage(newPageNumber);
    else return;
  }

  function handlePageSizeChange(event: any) {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  }

  useEffect(() => {
    populateListView(1, true);
  }, [page, pageSize, searchResults, isEdited]);

  return (
    <div>
      <div>
        <div>
          <div className="flex flex-col sm:flex-row justify-between items-end">
            <div className="sm:flex-auto">
              <Title
                title={title + 's'}
                desc={desc}
              />
            </div>
            <div className="mt-4 sm:mt-0">
              <Button
                type="submit"
                text={`Add ${title}`}
                onClick={() => onAddButtonClick(true)}
              ></Button>
            </div>
            {searchBar && (
              <div className="w-full sm:w-1/4 sm:mx-4 mt-4 sm:mt-0">
                {React.cloneElement(searchBar as ReactElement, { onSearchResults: handleSearchResults })}
              </div>
            )}
          </div>
        </div>

        <div className="mt-4 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className={`min-w-full divide-y ${darkMode ? 'divide-gray-700' : 'divide-gray-300'}`}>
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-foreground sm:pl-6"
                    >
                      {columns[0]}
                    </th>
                    {columns.slice(1, -1).map((item) => (
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-foreground"
                      >
                        {item}
                      </th>
                    ))}
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-center text-sm font-semibold text-foreground"
                    >
                      {columns[columns.length - 1]}
                    </th>
                  </tr>
                </thead>
                <tbody className={`divide-y ${darkMode ? 'divide-gray-700' : 'divide-gray-300'}`}>
                  {(searchResults.length > 0 ? rows : rows).map((row, rowIndex) => (
                    <tr
                      key={rowIndex}
                      onMouseUp={() => handleRowClick(row)}
                      className="cursor-pointer transition duration-300 ease-in-out hover:bg-foreground/5 text-red-500"
                    >
                      {rowData.map((property, index) => (
                        <td
                          key={property}
                          className={`whitespace-nowrap px-3 py-4 text-sm text-foreground ${
                            index === 0 ? 'pl-6 pr-3 font-medium sm:pl-6' : ''
                          }`}
                        >
                          {property === 'enabled' ? (
                            <div className="flex justify-center">
                              {row.enabled ? (
                                <CheckCircleIcon className="w-6 h-6 text-green-600" />
                              ) : (
                                <XCircleIcon className="w-6 h-6" />
                              )}
                            </div>
                          ) : (
                            row[property]
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* PAGINATION START*/}
      <nav
        className="flex items-center justify-between border-t border-secondary bg-shell px-4 py-3 sm:px-6 rounded-md mt-6 shadow-sm"
        aria-label="Pagination"
      >
        <div>
          <p className="text-medium text-foreground">
            <span className="hidden sm:inline">Showing </span>
            <span className="font-medium">
              {searchResults.length > 0 ? page * pageSize + 1 : page * pageSize + 1}
            </span>{' '}
            -{' '}
            <span className="font-medium">
              {searchResults.length > 0
                ? Math.min(searchResults.length, (page + 1) * pageSize)
                : Math.min(totalRecordCount, (page + 1) * pageSize)}
            </span>{' '}
            of <span className="font-medium">{searchResults.length > 0 ? searchResults.length : totalRecordCount}</span>{' '}
            results
          </p>
        </div>
        <div className="flex flex-1 sm:justify-end items-center">
          <div className="flex items-center">
            <label
              htmlFor="rowCount"
              className="hidden sm:inline pr-2 text-sm font-medium leading-6 text-foreground"
            >
              Rows per page
            </label>
            <select
              id="rowCount"
              name="rowCount"
              className="rounded-md border-0 py-2.5 pl-3.5 pr-0.5 text-sm text-foreground font-medium bg-background hover:bg-background/90"
              defaultValue={pageSize}
              onChange={handlePageSizeChange}
            >
              <option>25</option>
              <option>50</option>
              <option>75</option>
              <option>100</option>
            </select>
          </div>
          <Button
            className="ml-3 py-2"
            type="submit"
            text="Previous"
            onClick={handlePreviousButtonClick}
            disabled={page <= 0}
          ></Button>
          <Button
            className="ml-3 py-2"
            type="submit"
            text="Next"
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(totalRecordCount / pageSize)}
          ></Button>
        </div>
      </nav>
      {/* PAGINATION END */}
    </div>
  );
}

import React, { ChangeEvent, useEffect } from 'react';
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import SourceFeed from '../../models/SourceFeed';
import Category from '../../models/Category';
import MultiselectList from '../../components/Controls/MultiselectList/MultiselectList';
import Config from '../../app-config.json';
import LoadingSpinner from '../../components/Controls/LoadingIcons/LoadingSpinner';
import { useForm, SubmitHandler, FieldError, Controller } from 'react-hook-form';
import axios from 'axios';

//Parent component passes handlerFunction to call on when this component needs to be closed.
type props = {
  onClose: () => void;
};

export default function CreateNewSourceFeedDialog({ onClose }: props) {
  //Toby: Unsure whether this is setOpen useState() necessary or not. Parent is opening this component and its onClose() function is closing it.
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [assignedCategories, setAssignedCategories] = useState<Category[]>([]);
  const [dbCategories, setDbCategories] = useState<Category[]>([]);
  const [pendingChanges, setPendingChanges] = useState(false);
  const [showConfirmWindow, setShowConfirmWindow] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<SourceFeed>({
    defaultValues: {
      //Because select box for languageCodeID is disabled, we need to have a default value set so it is passed with the submission of form.
      languageCodeID: 1,
      categories: [], // Initial default value
    },
  });

  useEffect(() => {
    //fetch ALL categories from DB to reference in MultiselectList
    axios.get(`${Config.apiRootURL}/sourcefeed/categories`).then((json2) => {
      let tempList: Category[] = json2.data;
      tempList.sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      //After all Db categories are pulled and added to MultiselectList component, setOpen to true so Dialog opens.
      setDbCategories(json2.data);
      setOpen(true);
    });
    return () => {};
  }, []);

  const checkPendingChanges = () => {
    if (pendingChanges != true) {
      setPendingChanges(true);
    }
  };

  //Updates the source feed's list of categories via new list passed to it from the MultiSelect component after sorting them alphabetically.
  const handleSelectionChange = (updatedSelection: Category[]) => {
    setValue('categories', updatedSelection, { shouldValidate: true });
    updatedSelection.sort(function (a, b) {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    //After updatedSelection is is updated with more of less selected categories,
    //it is sorted alphabetically, assigned to assignedCategores, and pendingChanges changes to true;
    setAssignedCategories(() => {
      setPendingChanges(true);
      return updatedSelection;
    });
  };

  // Handler to close the this component, using onClose() function passed through props by parent component.
  const handleClose = () => {
    if (!pendingChanges) {
      // Close the drawer when clicked outside
      onClose();
    } else {
      setShowConfirmWindow(true);
    }
  };

  function timeInputToDateConversion(data: any) {
    let temp: string = data.toString();
    const [hours, minutes] = temp.split(':').map(Number);
    let finalDate = new Date();
    finalDate.setHours(hours);
    finalDate.setMinutes(minutes);
    return finalDate;
  }

  const onSubmit: SubmitHandler<SourceFeed> = (data) => {
    let sourceFeedDataWrapper = new SourceFeed(data);

    if (data.crawlWindowMin) {
      sourceFeedDataWrapper.crawlWindowMin = timeInputToDateConversion(data.crawlWindowMin);
    }

    if (data.crawlWindowMax) {
      sourceFeedDataWrapper.crawlWindowMax = timeInputToDateConversion(data.crawlWindowMax);
    }

    axios
      .post(`${Config.apiRootURL}/sourcefeed`, sourceFeedDataWrapper)
      .then((response) => {
        onClose(); // Call the onClose function if successful
        window.alert(`${response.status} Source feed created!`);
      })
      .catch((error) => {
        console.error('Error while saving:', error);
        window.alert(`Failed to create source feed: ${error.response?.status || ''}`);
      });
  };

  return (
    <Transition.Root
      show={open}
      as={Fragment}
    >
      <Dialog
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <div className="fixed inset-0  mt-16  z-10 w-100 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative h-full w-full transform overflow-auto rounded-lg bg-shell sm:px-4 pb-4 sm:pt-5 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl ">
                <div className="px-4 sm:gap-4 sm:px-0">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="pb-3 flex items-center justify-between border-b">
                      <label className="text-center w-full text-xl leading-6 text-foreground">New Source Feed</label>
                      <button
                        type="button"
                        onClick={handleClose}
                        className="z-50 rounded-md text-primary hover:text-gray-500 focus:outline-none ml-auto"
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      </button>
                    </div>

                    <div
                      className="h-full sm:px-12 sm:py-8 py-4"
                      aria-hidden="true"
                    >
                      {/*START - SourceFeed details content */}

                      <div className="mb-4">
                        <label className="block text-md font-medium leading-6 text-foreground">Name</label>
                        <div className="pt-2">
                          <input
                            {...register('name', {
                              required: 'Must provide a name for source feed.',
                              minLength: { value: 3, message: 'Name must be a minimum length of 3 characters.' },
                              maxLength: { value: 255, message: 'Name is too long.' },
                            })}
                            onChange={checkPendingChanges}
                            type="text"
                            className={`block w-full rounded-md border p-1.5 bg-background/50 text-foreground shadow-sm ring-1 ring-secondary placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-tertiary disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 text-base sm:leading-6 ${
                              errors.name ? 'border-red-500' : 'border-0'
                            }`}
                            placeholder="Source feed name"
                          />
                          <p className="text-alert pt-1">{errors.name?.message}</p>
                        </div>
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="url"
                          className="block text-md font-medium leading-6 text-foreground"
                        >
                          Domain
                        </label>
                        <div className="pt-2">
                          <input
                            {...register('sourceDomain', {
                              required: 'Must provide source feed domain URL.',
                              pattern: {
                                value: /^(https?:\/\/)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}(\/.*)?$/,
                                message: 'Invalid URL. Please specify protocol and TLD.',
                              },
                            })}
                            onChange={checkPendingChanges}
                            placeholder="https://exampledomain.com/"
                            type="text"
                            className={`block w-full rounded-md border p-1.5 bg-background/50 text-foreground shadow-sm ring-1 ring-secondary placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-tertiary disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 text-base sm:leading-6 ${
                              errors.sourceDomain ? 'border-red-500' : 'border-0'
                            }`}
                          />
                          <p className="text-alert pt-1">{errors.sourceDomain?.message}</p>
                        </div>
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="email"
                          className="block text-md font-medium leading-6 text-foreground"
                        >
                          Url
                        </label>
                        <div className="pt-2">
                          <input
                            {...register('locationURL', {
                              required: 'Must provide source feed URL.',
                              pattern: {
                                value: /^(https?:\/\/)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}(\/.*)?$/,
                                message: 'Invalid URL. Please specify protocol and TLD.',
                              },
                            })}
                            onChange={checkPendingChanges}
                            type="text"
                            className={`block w-full rounded-md border p-1.5 bg-background/50 text-foreground shadow-sm ring-1 ring-secondary placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-tertiary disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 text-base sm:leading-6 ${
                              errors.locationURL ? 'border-red-500' : 'border-0'
                            }`}
                            placeholder="https://exampledomain.com/examplefeed/"
                          />
                          <p className="text-alert pt-1">{errors.locationURL?.message}</p>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="formatType"
                          className=" text-md font-medium leading-6 text-foreground"
                        >
                          Format Type
                        </label>
                        <div className="pt-2">
                          {' '}
                          <select
                            {...register('formatTypeID', { required: 'Must select format type.' })}
                            onChange={checkPendingChanges}
                            id="formatTypeID"
                            name="formatTypeID"
                            className={`pt-2 block w-28 sm:w-40 rounded-md border p-1.5 text-foreground ring-1 ring-inset bg-background/50 ring-secondary focus:ring-1 focus:ring-tertiary text-base sm:leading-6 ${
                              errors.formatTypeID ? 'border-red-500' : 'border-0'
                            }`}
                          >
                            <option value="">Select</option>
                            <option value={1}>RssXml</option>
                            <option value={2}>Atom</option>
                            <option value={3}>JSON</option>
                          </select>
                          <p className="text-alert pt-1">{errors.formatTypeID?.message}</p>
                        </div>
                      </div>
                    </div>
                    {/* Categories START */}
                    <div className="sm:px-12 sm:py-8 py-4 sm:gap-4 border-t border-tertiary">
                      <div className="text-md font-medium leading-6 text-foreground">Categories</div>

                      <Controller
                        name="categories"
                        control={control}
                        rules={{ validate: (value) => value.length > 0 || 'At least one category must be selected' }}
                        render={({ field }) => (
                          <MultiselectList
                            dbList={dbCategories}
                            preAssignedList={assignedCategories}
                            onSelectionChange={handleSelectionChange}
                            errorInSelection={errors.categories ? true : false}
                          />
                        )}
                      />

                      {errors.categories && <p className="text-red-500">{errors.categories.message}</p>}
                    </div>
                    {/* Categories END */}
                    <div className="sm:px-12 sm:py-8 py-4 border-t border-tertiary">
                      <div className="grid grid-cols-2">
                        <div>
                          <label
                            htmlFor="regionType"
                            className="text-md font-medium leading-6 text-foreground"
                          >
                            Region Type
                          </label>
                          <div className="pt-2">
                            <select
                              {...register('regionTypeID', { required: 'Must select Region type.' })}
                              onChange={checkPendingChanges}
                              id="regionTypeID"
                              name="regionTypeID"
                              className={`pt-2 block w-28 sm:w-40 rounded-md border p-1.5 text-foreground ring-1 ring-inset bg-background/50 ring-secondary focus:ring-1 focus:ring-tertiary text-base sm:leading-6 ${
                                errors.regionTypeID ? 'border-red-500' : 'border-0'
                              }`}
                            >
                              <option value="">Select</option>
                              <option value={1}>Universal</option>
                              <option value={2}>Global</option>
                              <option value={3}>National</option>
                              <option value={4}>Regional</option>
                              <option value={5}>State</option>
                              <option value={6}>County</option>
                              <option value={7}>City</option>
                              <option value={8}>Hyperlocal</option>
                            </select>
                            <p className="text-alert pt-1">{errors.regionTypeID?.message}</p>
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="location"
                            className="text-md font-medium leading-6 text-foreground"
                          >
                            Location
                          </label>
                          <div className="pt-2">
                            <select
                              disabled={true}
                              onChange={checkPendingChanges}
                              id="location"
                              name="location"
                              autoComplete="location"
                              className="pt-2 block w-28 sm:w-40 rounded-md border-0 p-1.5 text-foreground ring-1 ring-inset bg-background/50 ring-secondary focus:ring-1 focus:ring-tertiary sm:text-base  sm:leading-6"
                            >
                              <option>TO DO</option>
                            </select>
                          </div>
                        </div>
                        <div className="pt-6">
                          <label
                            htmlFor="formatType"
                            className="text-md font-medium leading-6 text-foreground"
                          >
                            Language
                          </label>
                          <div className="pt-2">
                            <select
                              //TODO: {...register('languageCodeID')}
                              disabled={true}
                              onChange={checkPendingChanges}
                              id="language"
                              name="languageCodeID"
                              value={1}
                              defaultValue={1}
                              className="pt-2 block w-28 sm:w-40 rounded-md border-0 p-1.5 text-foreground ring-1 ring-inset bg-background/50 ring-secondary focus:ring-1 focus:ring-tertiary text-base sm:leading-6"
                            >
                              <option value={1}>English</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="sm:py-8 py-4 sm:px-12 border-t border-tertiary">
                      <div>
                        <div className="grid grid-cols-2 ">
                          <div>
                            <div>
                              <label
                                htmlFor="regionType"
                                className="text-md font-medium leading-6 text-foreground"
                              >
                                Crawl Win Min
                              </label>
                              <div className="pt-2">
                                <input
                                  {...register('crawlWindowMin')}
                                  onChange={checkPendingChanges}
                                  aria-label="Time"
                                  type="time"
                                  className="pt-2 block w-30 sm:w-30 rounded-md border-0 p-1.5 text-foreground ring-1 ring-inset bg-background/50 ring-secondary focus:ring-1 focus:ring-tertiary text-base  sm:leading-6"
                                />
                              </div>
                            </div>
                            <div className="pt-6">
                              <label
                                htmlFor="regionType"
                                className="text-md font-medium leading-6 text-foreground"
                              >
                                Crawl Interval
                              </label>
                              <div className="pt-2">
                                {' '}
                                <select
                                  {...register('crawlInterval', { required: 'Must specify crawl interval.' })}
                                  onChange={checkPendingChanges}
                                  className={`pt-2 block w-28 sm:w-40 rounded-md border p-1.5 text-foreground ring-1 ring-inset bg-background/50 ring-secondary focus:ring-1 focus:ring-tertiary text-base sm:leading-6 ${
                                    errors.crawlInterval ? 'border-red-500' : 'border-0'
                                  }`}
                                >
                                  <option value="">Select</option>
                                  <option value={30}>30 mins</option>
                                  <option value={60}>1 hour</option>
                                  <option value={120}>2 hours</option>
                                  <option value={240}>4 hours</option>
                                  <option value={360}>6 hours</option>
                                  <option value={480}>8 hours</option>
                                  <option value={720}>12 hours</option>
                                  <option value={1440}>24 hours</option>
                                </select>
                                <p className="text-alert pt-1">{errors.crawlInterval?.message}</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div>
                              <label
                                htmlFor="regionType"
                                className="text-md font-medium leading-6 text-foreground"
                              >
                                Crawl Win Max
                              </label>

                              <div className="pt-2">
                                <input
                                  {...register('crawlWindowMax')}
                                  onChange={checkPendingChanges}
                                  aria-label="Time"
                                  type="time"
                                  className="pt-2 block w-30 sm:w-30 rounded-md border-0 p-1.5 text-foreground ring-1 ring-inset bg-background/50 ring-secondary focus:ring-1 focus:ring-tertiary text-base  sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5 sm:mt-6 ">
                      <input
                        type="submit"
                        className="inline-flex w-full justify-center rounded-md bg-primary text-white px-3 py-2 sm:text-base font-semibold shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-information"
                        value="Create"
                      />
                    </div>
                    {/*End - SourceFeed details content */}
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
        <Transition.Root
          show={showConfirmWindow}
          as={Fragment}
        >
          <Dialog
            className="fixed inset-y-10 inset-x-0 z-10 "
            onClose={() => setShowConfirmWindow(false)}
          >
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This is the dialog box */}
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className=" inline-block align-bottom bg-shell rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="bg-shell sm:px-4 pt-5 pb-4 sm:p-6 sm:pb-4 px-8 text-center sm:text-left text-foreground">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3  text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-foreground"
                        >
                          Are you sure...
                        </Dialog.Title>
                      </div>
                    </div>
                    you want to leave without creating source feed?
                  </div>
                  <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary/70 focus:outline-none  sm:ml-3 sm:w-auto"
                      onClick={() => {
                        // Perform action when "Yes" is clicked
                        setShowConfirmWindow(false);
                        setOpen(false);
                        onClose();
                      }}
                    >
                      Leave
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-500 text-base font-medium text-white hover:bg-gray-500/70 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto "
                      onClick={() => setShowConfirmWindow(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </Dialog>
    </Transition.Root>
  );
}

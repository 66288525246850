import { XMarkIcon, PencilIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon, ExclamationCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/16/solid';
import { useEffect, useRef, useState } from 'react';
import SourceFeed from '../../../models/SourceFeed';
import FormatType, { getFormatTypeString } from '../../../models/FormatType';
import Config from '../../../app-config.json';
import { getRegionTypeString } from '../../../models/RegionType';
import { getLanguageTypeString } from '../../../models/LanguageType';
import LoadingSpinner from '../../Controls/LoadingIcons/LoadingSpinner';
import User from '../../../models/User';
import Organization from '../../../models/Organization';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import UserActivityLog from '../../../models/UserActivityLog';
import axios from 'axios';

let emptyUser: User = new User([]);

type props = {
  refreshData: boolean;
  userId: string;
};

//format for activity log statuses
const format: Intl.DateTimeFormatOptions = {
  month: '2-digit',
  year: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
};

export default function UserDetailsReadOnlyDrawer({ refreshData, userId }: props) {
  const [user, setUser] = useState<User>(emptyUser);
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState<UserActivityLog[]>([]);

  useEffect(() => {
    //TODO: organization ID hardcoded for now to 1. Need to make dynamic later
    setLoading(true);
    getUserById(1, userId);
    return () => {};
  }, [refreshData, userId]); // Trigger effect when 'open' state changes

  //API calls to get User data, their assigned roles, their Organization details and lastest 100 activity logs
  function getUserById(orgId: number, userId: string) {
    //Fetch user details
    axios
      .get(`${Config.apiRootURL}/organization/${orgId}/user/${userId}`)
      .then((json1) => {
        let tempUser: User = new User(json1.data);

        //Fetch the roles assigned to the User
        axios
          .get(`${Config.apiRootURL}/organization/${orgId}/user/${userId}/role`)
          .then((json2) => {
            tempUser.roles = json2.data;

            //Fetch the Organization of the User
            axios
              .get(`${Config.apiRootURL}/organization/${orgId}?includeUsers=false`)
              .then((json3) => {
                let tempOrg: Organization = new Organization(json3.data);
                tempUser.organization = tempOrg;

                //Fetch last 100 Activity Log statuses of user
                axios.get(`${Config.apiRootURL}/organization/${orgId}/user/${userId}/log/100`).then((json4) => {
                  var entries = json4.data.map((item: UserActivityLog) => {
                    return { ...item, timestamp: new Date(item.timestamp) };
                  });
                  setUser(tempUser);
                  setLoading(false); // Set loading to false when all data is fetched
                  setLogs(entries);
                });
              })
              .catch((error) => {
                console.error("Error fetching User's organization:", error);
              });
          })
          .catch((error) => {
            console.error('Error fetching author details:', error);
          });
      })
      .catch((error) => {
        console.error('Error while fetching User:', error);
      });
  }

  //Activity Log JSX
  const renderRow = ({ index, style }: ListChildComponentProps) => {
    const item = logs[index];

    return (
      <div>
        <div
          style={{ ...style, paddingLeft: 0 }}
          key={index}
          className="flex items-center border-b py-2 pl-0"
        >
          <div
            className="text-sm text-foreground"
            style={{ maxWidth: 'fit-content', paddingRight: 30 }}
          >
            {`${Intl.DateTimeFormat('en-us', format)
              .format(item.timestamp as Date)
              .replace(',', '')}:`}
          </div>
          <div
            className="text-sm text-foreground"
            style={{ maxWidth: 'fit-content', paddingRight: 15 }}
          >
            {`${item.access}`}
          </div>
        </div>
      </div>
    );
  };

  // Utility function to generate a random width
  const getRandomWidth = () => {
    const widths = ['w-28', 'w-32', 'w-36'];
    return widths[Math.floor(Math.random() * widths.length)];
  };

  return (
    <div className=" h-full">
      <div className="px-4 sm:gap-4 sm:px-0">
        <div
          className="h-full pb-8 pt-4 "
          aria-hidden="true"
        >
          {/*START - SourceFeed details content */}
          <div className="mb-4">
            <div className="flex justify-between items-start">
              <div className="flex-grow mr-4">
                {' '}
                {/* Added flex-grow and margin-right */}
                <h3 className="block sm:text-lg text-lg font-bold pt-2 leading-6 text-foreground">Name</h3>
                <div
                  className={`${loading ? 'mt-2 sm:text-base text-sm drop-shadow-sm animate-pulse-slow bg-primary/20 col-span-2 h-7 w-2/3 rounded-lg' : ' text-2xl pt-2 leading-6 text-foreground'}`}
                >
                  {loading ? '' : user.getFullName()}
                </div>
              </div>
              {loading ? (
                <span className="animate-pulse-slow mr-1 my-2 inline-flex items-center rounded-full bg-transparent px-2 py-1 sm:text-base text-sm font-medium text-primary/60 ring-1 ring-inset ring-primary/60">
                  Status
                </span>
              ) : user?.enabled ? (
                <span className="mr-1 my-2 inline-flex items-center rounded-full bg-transparent px-2 py-1 sm:text-base text-sm font-medium text-green-600 ring-1 ring-inset ring-green-600">
                  Active
                </span>
              ) : (
                <span className="mr-1 my-2 inline-flex items-center rounded-full bg-transparent px-2 py-1 sm:text-base text-sm font-medium text-red-600 ring-1 ring-inset ring-red-600">
                  Disabled
                </span>
              )}
            </div>
          </div>
          <div className="mb-4">
            <div className="flex items-center">
              <h3 className="block sm:text-lg text-md font-bold pt-2 leading-6 text-foreground ">Email</h3>
              {loading ? (
                <QuestionMarkCircleIcon className=" animate-pulse-slow h-4 w-4 m-1 text-primary/60" />
              ) : user?.emailVerified ? (
                <CheckCircleIcon
                  className="h-4 w-4 m-1 text-green-600"
                  title="Email is verified"
                />
              ) : (
                <ExclamationCircleIcon
                  className="h-4 w-4 m-1 text-warning"
                  title="Email is not verified"
                />
              )}
            </div>
            <div
              className={`${loading ? 'mt-2  animate-pulse-slow  bg-primary/20 col-span-2 h-7 w-2/3 rounded-lg ' : 'pt-2 leading-6 text-foreground '}`}
            >
              {' '}
              {loading ? '' : user?.email}
            </div>
          </div>
          <div className="grid grid-cols-2">
            <div className="mb-4">
              <h3 className="block sm:text-lg text-md font-bold pt-2  leading-6 text-foreground">Username</h3>
              <div
                className={`${loading ? 'mt-2 sm:text-base text-sm animate-pulse-slow bg-primary/20 col-span-2 h-7 w-5/6 rounded-lg' : 'pt-2 leading-6 text-foreground'}`}
              >
                {loading ? '' : user?.username}
              </div>
            </div>
            <div className="mb-4">
              <h3 className="block sm:text-lg text-md font-bold pt-2  leading-6 text-foreground">Organization</h3>
              <div
                className={`${loading ? 'mt-2 sm:text-base text-sm animate-pulse-slow bg-primary/20 col-span-2 h-7 w-5/6 rounded-lg' : 'pt-2 leading-6 text-foreground'}`}
              >
                {loading ? '' : user.organization?.organizationName}
              </div>
            </div>
          </div>
        </div>

        <div className="py-8 border-t border-tertiary">
          <div className="grid grid-cols-2">
            <div>
              <h3 className="block sm:text-lg text-md font-bold pt-2  leading-6 text-foreground">Last Login</h3>

              <div
                className={`${loading ? 'mt-2 sm:text-base text-sm animate-pulse-slow  bg-primary/20 col-span-2 h-7 w-2/3 rounded-lg' : 'pt-2 leading-6 text-foreground'}`}
              >
                {loading ? '' : user?.lastLogin.toDateString()}
              </div>
            </div>
            <div>
              <h3 className="block sm:text-lg text-md font-bold pt-2  leading-6 text-foreground">Date Created</h3>

              <div
                className={`${loading ? 'mt-2 sm:text-base text-sm animate-pulse-slow bg-primary/20 col-span-2 h-7 w-2/3 rounded-lg' : 'pt-2 leading-6 text-foreground'}`}
              >
                {loading ? '' : user?.created.toDateString()}
              </div>
            </div>
            <div className="pt-6">
              <h3 className="block sm:text-lg text-md font-bold pt-2  leading-6 text-foreground">Password Changed</h3>

              <div
                className={`${loading ? 'mt-2 sm:text-base text-sm animate-pulse-slow bg-primary/20 col-span-2 h-7 w-2/3 rounded-lg' : 'pt-2 leading-6 text-foreground'}`}
              >
                {loading ? '' : user?.passwordLastChanged.toDateString()}
              </div>
            </div>
          </div>
        </div>
        <div className="py-8 border-t border-tertiary">
          {' '}
          <div className="block sm:text-lg text-md font-bold pt-2  leading-6 text-foreground">
            <h2>User Roles</h2>
            <div className="flex-wrap flex-grow pt-2">
              {loading ? (
                <div>
                  <span
                    className={`${getRandomWidth()} drop-shadow-md animate-pulse-delay-200 h-7 mr-1 my-1 inline-flex items-center rounded-md bg-secondary/30 px-2 py-1 text-sm font-medium text-foreground ring-1 ring-inset ring-tertiary `}
                  />
                  <span
                    className={`${getRandomWidth()} drop-shadow-md animate-pulse-delay-400 h-7 mr-1 my-1 inline-flex items-center rounded-md bg-secondary/30 px-2 py-1 text-sm font-medium text-foreground ring-1 ring-inset ring-tertiary `}
                  />
                  <span
                    className={`${getRandomWidth()} drop-shadow-md animate-pulse-delay-600 h-7 mr-1 my-1 inline-flex items-center rounded-md bg-secondary/30 px-2 py-1 text-sm font-medium text-foreground ring-1 ring-inset ring-tertiary `}
                  />
                </div>
              ) : user.roles.length < 1 ? (
                <p className="leading-6 text-foreground font-normal">No assigned roles.</p>
              ) : (
                <div>
                  {user.roles.map((role, index) => (
                    <span
                      key={role.id}
                      className="drop-shadow-md mr-1 my-1 inline-flex items-center rounded-md bg-secondary/50 px-2 py-1 text-sm font-medium text-foreground ring-1 ring-inset ring-tertiary"
                    >
                      {role.name}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="py-8 border-t border-tertiary">
          {' '}
          <div className="block text-md pt-2  leading-6 text-foreground">
            <h4 className="sm:text-lg text-md font-semibold">Activity log:</h4>
            <div className="relative h-80 pt-2">
              {loading ? (
                <div className="flex animate-bounce items-center justify-center absolute inset-x-0 top-1/4 transform -translate-y-8">
                  <LoadingSpinner size="h-8" />
                </div>
              ) : logs.length < 1 ? (
                <p className="leading-6 sm:text-base text-sm text-foreground font-normal">No recorded activity</p>
              ) : (
                <FixedSizeList
                  height={350}
                  width="100%"
                  itemSize={40} // Adjust this value to suit your item height
                  itemCount={logs.length}
                  overscanCount={10}
                >
                  {renderRow}
                </FixedSizeList>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
